<template>
    <div>
        <div class="top-header blackBG border-bottom">
            <div class="marquee border-top-0 border-bottom">
                <banner-slider></banner-slider>
            </div>
        </div>
        <section class="blockElement innerPage-banner">
            <div class="container">
                <div class="row">
                    <div class="col-12"> 
                        <div class="breadCrum">
                                <h2>Indicator List</h2>
                                <nav aria-label="breadcrumb mt-2">
                                    <ol class="breadcrumb mb-0">
                                        <li class="breadcrumb-item"><router-link class="green" to="/dashboard">Home</router-link></li>
                                        <li class="breadcrumb-item active" aria-current="page">Indicator List</li>
                                    </ol>
                                </nav>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section class="blockElement indicatorBlock space-footer">
            <div class="container">
                <div class="row">
                    <div class="col-12 col-lg-3 col-md-6 mb-4">
                        <router-link to="/gdp-growth-rate-by-country" class="card-body">
                            <span class="maskIcon img1 mb-2"></span>
                            <div class="content">
                               <h5 class="f-20 medium">Main Indicator</h5>
                               <p>A catalogue of most crucial indicators. </p>
                               <span class="d-flex align-items-center">Deatils <vue-feather size="18px" class="ms-1" type="arrow-right"></vue-feather></span>
                            </div>
                        </router-link>
                    </div>
                    <div class="col-12 col-lg-3 col-md-6 mb-4">
                        <router-link to="/gdp-growth-rate-by-country" class="card-body">
                            <span class="maskIcon img2 mb-2"></span>
                            <div class="content">
                               <h5 class="f-20 medium">Markets</h5>
                               <p>Watch the global market comprising variety of instruments.</p>
                               <span class="d-flex align-items-center">Deatils <vue-feather size="18px" class="ms-1" type="arrow-right"></vue-feather></span>
                            </div>
                        </router-link>
                    </div>
                    <div class="col-12 col-lg-3 col-md-6 mb-4">
                        <router-link to="/gdp-growth-rate-by-country" class="card-body">
                            <span class="maskIcon img3 mb-2"></span>
                            <div class="content">
                               <h5 class="f-20 medium">GDP</h5>
                               <p>Evaluate economic production and growth, or the size of the economy.</p>
                               <span class="d-flex align-items-center">Deatils <vue-feather size="18px" class="ms-1" type="arrow-right"></vue-feather></span>
                            </div>
                        </router-link>
                    </div>
                    <div class="col-12 col-lg-3 col-md-6 mb-4">
                        <router-link to="/gdp-growth-rate-by-country" class="card-body">
                            <span class="maskIcon img4 mb-2"></span>
                            <div class="content">
                               <h5 class="f-20 medium">Labour</h5>
                               <p>Assess the job a market with the variety of indicators. </p>
                               <span class="d-flex align-items-center">Deatils <vue-feather size="18px" class="ms-1" type="arrow-right"></vue-feather></span>
                            </div>
                        </router-link>
                    </div>
                    <div class="col-12 col-lg-3 col-md-6 mb-4">
                        <router-link to="/gdp-growth-rate-by-country" class="card-body">
                            <span class="maskIcon img5 mb-2"></span>
                            <div class="content">
                               <h5 class="f-20 medium">Pricing</h5>
                               <p>A set of indices to calculate the change in price of a set of goods in an economy.</p>
                               <span class="d-flex align-items-center">Deatils <vue-feather size="18px" class="ms-1" type="arrow-right"></vue-feather></span>
                            </div>
                        </router-link>
                    </div>
                    <div class="col-12 col-lg-3 col-md-6 mb-4">
                        <router-link to="/gdp-growth-rate-by-country" class="card-body">
                            <span class="maskIcon img6 mb-2"></span>
                            <div class="content">
                               <h5 class="f-20 medium">Consumers</h5>
                               <p>Watch the global market comprising variety of instruments.</p>
                               <span class="d-flex align-items-center">Deatils <vue-feather size="18px" class="ms-1" type="arrow-right"></vue-feather></span>
                            </div>
                        </router-link>
                    </div>
                    <div class="col-12 col-lg-3 col-md-6 mb-4">
                        <router-link to="/gdp-growth-rate-by-country" class="card-body">
                            <span class="maskIcon img7 mb-2"></span>
                            <div class="content">
                               <h5 class="f-20 medium">Business</h5>
                               <p>Watch the global market comprising variety of instruments.</p>
                               <span class="d-flex align-items-center">Deatils <vue-feather size="18px" class="ms-1" type="arrow-right"></vue-feather></span>
                            </div>
                        </router-link>
                    </div>
                    <div class="col-12 col-lg-3 col-md-6 mb-4">
                        <router-link to="/gdp-growth-rate-by-country" class="card-body">
                            <span class="maskIcon img8 mb-2"></span>
                            <div class="content">
                               <h5 class="f-20 medium">Government</h5>
                               <p>Watch the global market comprising variety of instruments. </p>
                               <span class="d-flex align-items-center">Deatils <vue-feather size="18px" class="ms-1" type="arrow-right"></vue-feather></span>
                            </div>
                        </router-link>
                    </div>
                    <div class="col-12 col-lg-3 col-md-6 mb-4">
                        <router-link to="/gdp-growth-rate-by-country" class="card-body">
                            <span class="maskIcon img9 mb-2"></span>
                            <div class="content">
                               <h5 class="f-20 medium">Health</h5>
                               <p>Guge the health of people using various indicators .</p>
                               <span class="d-flex align-items-center">Deatils <vue-feather size="18px" class="ms-1" type="arrow-right"></vue-feather></span>
                            </div>
                        </router-link>
                    </div>
                    <div class="col-12 col-lg-3 col-md-6 mb-4">
                        <router-link to="/gdp-growth-rate-by-country" class="card-body">
                            <span class="maskIcon img10 mb-2"></span>
                            <div class="content">
                               <h5 class="f-20 medium">Housing</h5>
                               <p>A gauge for housing stock details, status, and changes over the Land.</p>
                               <span class="d-flex align-items-center">Deatils <vue-feather size="18px" class="ms-1" type="arrow-right"></vue-feather></span>
                            </div>
                        </router-link>
                    </div>
                    <div class="col-12 col-lg-3 col-md-6 mb-4">
                        <router-link to="/gdp-growth-rate-by-country" class="card-body">
                            <span class="maskIcon img11 mb-2"></span>
                            <div class="content">
                               <h5 class="f-20 medium">Money</h5>
                               <p>Watch the global market comprising variety of instruments.</p>
                               <span class="d-flex align-items-center">Deatils <vue-feather size="18px" class="ms-1" type="arrow-right"></vue-feather></span>
                            </div>
                        </router-link>
                    </div>
                    <div class="col-12 col-lg-3 col-md-6 mb-4">
                        <router-link to="/gdp-growth-rate-by-country" class="card-body">
                            <span class="maskIcon img12 mb-2"></span>
                            <div class="content">
                               <h5 class="f-20 medium">Tax</h5>
                               <p>Watch the global market comprising variety of instruments.</p>
                               <span class="d-flex align-items-center">Deatils <vue-feather size="18px" class="ms-1" type="arrow-right"></vue-feather></span>
                            </div>
                        </router-link>
                    </div>
                    <div class="col-12 col-lg-3 col-md-6 mb-4">
                        <router-link to="/gdp-growth-rate-by-country" class="card-body">
                            <span class="maskIcon img13 mb-2"></span>
                            <div class="content">
                               <h5 class="f-20 medium">Trade</h5>
                               <p>Watch the global market comprising variety of instruments.</p>
                               <span class="d-flex align-items-center">Deatils <vue-feather size="18px" class="ms-1" type="arrow-right"></vue-feather></span>
                            </div>
                        </router-link>
                    </div>
                    <div class="col-12 col-lg-3 col-md-6 mb-4">
                        <router-link to="/gdp-growth-rate-by-country" class="card-body">
                            <span class="maskIcon img14 mb-2"></span>
                            <div class="content">
                               <h5 class="f-20 medium">UN Comtrade</h5>
                               <p>Watch the global market comprising variety of instruments.</p>
                               <span class="d-flex align-items-center">Deatils <vue-feather size="18px" class="ms-1" type="arrow-right"></vue-feather></span>
                            </div>
                        </router-link>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
import bannerslider from "../../components/shared/banner_slider";
export default {
    data() {
        return {

        }
    },
    components: {
        bannerSlider:bannerslider,

    },     
}
</script>
